const workExperienceData = [
    {
        id: 1,
        workplace: "ENSEK, Nottingham",
        period: "Nov 2021 - Present",
        jobTitle: "Software Engineer I / II",
        jobDescription: [
            {
                description: "Typescript, React, Tailwind, AWS, CDK (Infrastructure as Code), Azure DevOps and more"
            },
            {
                description: "Revitalized a legacy frontend application within the SaaS platform through the adoption of micro-frontend architecture"
            },
            {
                description: "Developed a production-ready frontend setup using Next.js, TypeScript, Tailwind CSS, Styled Components and other technologies"
            },
            {
                description: "Contributed to infrastructure development by utilizing a range of AWS services and automating the deployment process (Azure Pipelines, Infrastructure as Code)"
            },
            {
                description: "Wrote unit tests (Jest) and integration tests (Cypress) ensuring comprehensive test coverage"
            },
            {
                description: "Took ownership of the release process for the micro-frontend infrastructure"
            },
            {
                description: "Promoted from Software Engineer I to Software Engineer II in recognition of performance and contributions to the team"
            }
        ]
    },
    {
        id: 2,
        workplace: "Igloo Energy, Southampton",
        period: "Dec 2020 - Nov 2021",
        jobTitle: "Associate Software Engineer",
        jobDescription: [
            {
                description: "Vue.js, Node.js, Amazon Web Services, Contentful, SQL"
            },
            {
                description: "Played a role in building a digital customer journey that is essential to the sale of heat pumps"
            },
            {
                description: "Participated in creation and maintenance of a system used by marketing staff to construct web pages in a content management system"
            },
            {
                description: "Agile team (remote) using both Kanban and Scrum. Performed code reviews and pair programming"
            },
            {
                description: "Developed dynamic, responsive web pages working with designers and product managers. Wrote frontend tests, cross-browser tested, and maintained these pages"
            },
            {
                description: "Created microservices integrated with various CRM services"
            },
            {
                description: "Used an ORM with a SQL database"
            }
        ]
    },
    {
        id: 3,
        workplace: "Self-employed, London",
        period: "Jan 2019 - Dec 2020",
        jobTitle: "Private Tutor",
        jobDescription: [
            {
                description: "Assisted Computer Science students with exam preparation and completion of projects"
            },
            {
                description: "Ensured a productive learning environment through rapport and consistency"
            }
        ]
    },
    {
        id: 4,
        workplace: "Middlesex University, London",
        period: "Sep 2018 - May 2020",
        jobTitle: "Student Learning Assistant",
        jobDescription: [
            {
                description: "Helped students develop their understanding of Object-Oriented Programming"
            },
            {
                description: "Created lesson plans and practice tests to help students achieve best results"
            },
            {
                description: "Received Peer Assisted Learning Award 2019 for Outstanding Performance"
            }
        ]
    },
    {
        id: 5,
        workplace: "Middlesex University, London",
        period: "Oct 2018 - Jun 2019",
        jobTitle: "Student Voice Leader",
        jobDescription: [
            {
                description: "Worked closely with students and departmental staff to help improve the quality of the programme"
            },
            {
                description: "Participated in regular sessions with students and presented their feedback during the Board of Studies meetings"
            }
        ]
    },
    {
        id: 6,
        workplace: "Data City Computers, Camberley",
        period: "May 2017 - Aug 2018",
        jobTitle: "IT Support / Sales",
        jobDescription: [
            {
                description: "Performed routine maintenance and hardware/software upgrades to end user PCs and related equipment"
            }, {
                description: "Provided technical support to customers via telephone, email and in person"
            }
        ]
    }
]

export default workExperienceData