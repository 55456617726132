import React from 'react';

const Profile = () => {
    return (
        <section id="profile">
            <div className="container section-margin">
                {/* profile section row */}
                <div className="row" data-aos="fade-down">
                    {/* section title and short description columns */}
                    <div className="col-sm-12 text-center">
                        <h2 className="section-title">Profile</h2>
                        <b className="col-sm-12 text-center quote-light-bg" style={{ fontStyle: 'normal' }}>
                            Software Engineer II
                        </b>
                        <hr />
                    </div>{' '}
                    {/* column */}
                </div>{' '}
                {/* profile section row */}
                {/* About me row */}
                <div className="row justify-content-md-center align-items-center" data-aos="zoom-in-down">
                    <div className="col-md-4">
                        <div id="profile-picture-wrapper">
                            <img
                                className="img-fluid profile-picture media-query-swap-picture-1"
                                src="img/profile-picture-1.jpg"
                                alt="profile-photo-1"
                            />
                        </div>
                        <div id="profile-picture-wrapper">
                            <img
                                className="img-fluid profile-picture media-query-swap-picture-2"
                                src="img/profile-picture-2.jpg"
                                alt="profile-photo-2"
                            />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <h3 className="sub-section-title text-center-media-query">About me</h3>

                        {/* <p className="section-text text-center-media-query">
                            Dedicated and results-driven Software Engineer with a passion for crafting innovative
                            solutions. Motivated by a passion for delivering top-tier results, I am eager to leverage my
                            extensive experience, robust technical skill set, and innate problem-solving abilities in a
                            dynamic and challenging environment.
                        </p>

                        <hr className="subsection-separator-dark separator-margins" /> */}

                        <p className="section-text text-center-media-query">
                            I have worked in agile teams using both <b>Kanban</b> and <b>Scrum</b>, performing code
                            reviews and pair programming. I have played a role in building a digital customer journey
                            that is essential to the sale of heat pumps. I developed dynamic, responsive web pages,
                            working with designers and product managers.
                        </p>

                        <hr className="subsection-separator-dark separator-margins" />
                        <p className="section-text text-center-media-query">
                            In my most recent role, I made significant contributions to the modernization of a legacy
                            application within the SaaS platform through the adoption of the <b>micro-frontend</b>{' '}
                            infrastructure.
                        </p>

                        <p className="section-text text-center-media-query">
                            I have also participated in the development of the <b>cloud infrastructure</b> by utilizing a range
                            of <b>AWS services</b> and automating the deployment process with Azure Pipelines and CDK
                            (<b>Infrastructure as Code</b>).
                        </p>

                        <hr className="subsection-separator-dark separator-margins" />
                        <p className="section-text text-center-media-query">
                            I have excelled in my studies and was able to receive distinction grades in all modules,
                            while working as a Student Learning Assistant (received an award for{' '}
                            <a href="#awards">
                                <em className="strong-color">Outstanding Performance</em>
                            </a>
                            , private tutor, and serving as Student Voice Leader at the same time. I was also awarded
                            with the prize for{' '}
                            <a href="#awards">
                                <em className="strong-color">Outstanding Achievement</em>
                            </a>{' '}
                            in the Faculty of Science and Technology.
                        </p>
                    </div>
                </div>
            </div>{' '}
            {/* container */}
        </section>
    );
};

export default Profile;
