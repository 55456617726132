const educationData =
{
    id: 1,
    workplace: "Middlesex University",
    period: "Sep 2017 - May 2020",
    jobTitle: "BSc Computer Science (1st Class)",
    jobDescription: [
        {
            description: "Main areas of the degree include object-oriented programming, web development, system design and software testing"
        }, {
            description: "Key modules: Advanced Web Development with Big Data, Web-Based Mobile App Development, Testing and Verification, Data and Industry"
        }
    ]
}


export default educationData