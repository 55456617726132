import React from "react"
import $ from "jquery"
import anime from 'animejs/lib/anime.es.js';

class Header extends React.Component {
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)

        this.handleAnimation()
    }

    handleScroll = event => {
        /* Jittery scrolling in Chrome mobile browser due to 'background-attachment = fixed' being used */

        /* checks current scroll position */
        let y_scroll_pos = window.pageYOffset;
        /* checks the height of the browser */
        let scroll_pos_test = $(window).height();
        let parallax = document.getElementById("parallax");

        /* if splashscreen is visible, use fixed, else use scroll */
        if (y_scroll_pos > scroll_pos_test) {
            parallax.style.backgroundAttachment = "scroll";
        } else {
            parallax.style.backgroundAttachment = "fixed";
        }
    }

    handleAnimation = () => {
        /* header text animation */
        let textWrapper = document.querySelector('.ml9 .letters');
        textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

        anime.timeline({
            loop: false
        })
            .add({
                targets: '.ml9 .letter',
                scale: [0, 1],
                duration: 1500,
                elasticity: 600,
                delay: (el, i) => 45 * (i + 15)
            }).add({
                targets: '.ml9',
                opacity: 0,
                duration: 1000,
                easing: "easeOutExpo",
                delay: 1000000
            });

        /* smooth scrolling animation from an id to another id (no need for scroll-behavior property) */
        $("#scroll-down-arrow").click(function () {
            $('html, body').animate({
                scrollTop: $("#profile").offset().top
            }, 1300);
        });
    }

    render() {
        return (
            <header id="parallax">
                <div id="overlay">
                    <div className="row h-100 align-items-center justify-content-md-center no-margins">
                        <div className="col-md-auto text-center">
                            <h1 className="ml9">
                                <span className="text-wrapper" id="overlay-header-interactive">
                                    <span className="letters">Maciej Bezzubik</span>
                                </span>
                            </h1>
                            <div id="overlay-header">
                                Maciej Bezzubik
                            </div>

                            <div id="overlay-lead">
                                Interactive resume
                            </div>
                        </div>
                    </div>
                    <a id="scroll-down-arrow"><i className="pulse-arrow fas fa-arrow-circle-down fa-4x"></i></a>
                </div> {/* overlay */}
            </header> /* parallax */
        )
    }
}

export default Header