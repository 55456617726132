import React from "react"
import SectionHeader from "../../shared/SectionHeader.jsx"
import ProjectItem from "./ProjectItem.jsx"
import projectsData from "./projectsData"

const Projects = () => {
    const projectsComponents = projectsData.map(project =>
        <ProjectItem
            key={project.id}
            data={project}
            projectCount={projectsData.length}
        />
    )

    return (
        <section id="projects">
            <div className="container section-margin" data-aos="fade-down">
                <SectionHeader
                    header="Projects"
                    quote='"Science is a way of thinking much more than it is a body of knowledge." - Carl Sagan'
                    classes="quote-light-bg"
                />

                {/* Project tiles */}
                <div className="project-tile text-center">
                    {projectsComponents}
                </div> {/* Project tiles */}
            </div> {/* Container */}
        </section>

    )
}

export default Projects
