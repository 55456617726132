import React from "react"
import $ from "jquery"
import SectionHeader from "../../shared/SectionHeader.jsx"
import PhotographyItem from "./PhotographyItem.jsx"
import photographyData from "./photographyData"
import bootstrap from "bootstrap"


class PersonalSkills extends React.Component {

    componentDidMount() {
        /* =============== Image Modal =============== */
        /* add pop class to gallery images when window width is larger than 768 */
        $(document).ready(function () {
            if ($(window).width() > 768) {
                $('.img-box').addClass('pop');
            }
        });

        $(function () {
            $('.pop').on('click', function () {
                $('.imagepreview').attr('src', $(this).find('img').attr('src'));
                $('#imagemodal').modal('show');
            });
        });

    }

    render() {
        const photographyComponents = photographyData.map(photo =>
            <PhotographyItem
                key={photo.id}
                data={photo}
            />
        )

        return (
            <section id="personal-skills">

                {/* Modal */}
                <div style={{ zIndex: 9999, paddingTop: '10px' }} className="modal fade" id="imagemodal" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog " style={{ maxWidth: '85%' }}>
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="close" data-dismiss="modal"><span className="sr-only">Close</span></button>
                                <img className="imagepreview" style={{ width: '100%' }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container section-margin">
                    <SectionHeader
                        header="Personal Skills and Achievements"
                        quote={'"I like to learn. That\'s an art and a science." - Katherine Johnson'}
                        classes="quote-light-bg"
                    />

                    {/* Awards sub-section header row */}
                    <div id="awards" className="row" data-aos="flip-right">
                        {/* Awards header column */}
                        <div className="col-sm-12">
                            <h3 className="sub-section-title text-center-media-query">Awards</h3>
                        </div>
                    </div>
                    {/* Awards content */}
                    <div className="row justify-content-center align-items-center achievements" data-aos="flip-left">
                        <h4 className="col-md-5 text-center"><strong>Outstanding Achievement Prize</strong></h4>
                        <div className="col-md-7 text-center"><img className="img-fluid" src="img/awards/outstanding-achievement.jpg" alt="english-icon" />
                        </div>
                        <h4 className="col-md-5 text-center"><strong>Peer Assisted Learning Award for Outstanding Performance</strong></h4>
                        <div className="col-md-7 text-center"><img className="img-fluid" src="img/awards/sla-award.jpg" alt="english-icon" />
                        </div>
                    </div> {/* Awards */}
                    <div className="col-sm-12">
                        <hr className="subsection-separator-dark" />
                    </div>
                    {/* Languages sub-section header row */}
                    <div className="row" data-aos="flip-right">
                        {/* Languages header column */}
                        <div className="col-sm-12">
                            <h3 className="sub-section-title text-center-media-query">Languages</h3>
                        </div>
                    </div>
                    {/* Foreign languages contents */}
                    <div className="row justify-content-center" data-aos="flip-left">
                        <div className="col-3 text-center"><img className="icons-small" src="img/icons/polish.png" alt="polish-icon" /><br />Polish
                (native)</div>
                        <div className="col-3 text-center"><img className="icons-small" src="img/icons/english.png" alt="english-icon" /><br />English
                (fluent)</div>
                        <div className="col-3 text-center"><img className="icons-small" src="img/icons/spanish.png" alt="spanish-icon" /><br />Spanish
                (basic)</div>
                        <div className="col-3 text-center"><img className="icons-small" src="img/icons/russian.png" alt="russian-icon" /><br />Russian
                (basic)</div>
                        <div className="col-sm-12">
                            <hr className="subsection-separator-dark" />
                        </div>
                    </div> {/* Foreign languages */}
                    {/* Photography sub-section header row */}
                    <div className="row" data-aos="flip-right">
                        {/* Programming Experience header column */}
                        <div className="col-sm-12">
                            <h3 className="sub-section-title text-center-media-query">Photography</h3>
                        </div>
                        <p className="text-center section-text">
                            Understanding photography allows me to speak a language that can be used to entertain,
                            educate
                            and move people around the world without saying a single word. A successful photo shoot is
                            universally understood regardless of upbringing, beliefs and other variables that make us
                            unique.
              </p>
                    </div>
                    {/* Image Gallery */}
                    <div className="gallery-image" data-aos="fade-up">
                        {photographyComponents}

                    </div>
                </div> {/* container */}
            </section>
        )
    }

}

export default PersonalSkills