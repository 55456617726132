import React from "react"
import Header from "./header/Header.jsx"
import Main from "./main/Main.jsx"
import Footer from "./footer/Footer.jsx"

class App extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <Main />
        <Footer />
      </div>
    )
  }
}

export default App;
