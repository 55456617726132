import React from "react"
import SectionHeader from "../../shared/SectionHeader.jsx"
import ExperienceItem from "./ExperienceItem.jsx"
import workExperienceData from "./workExperienceData"
import educationData from "./educationData"


const Experience = () => {

    const experienceItemComponents = workExperienceData.map(item =>
        <ExperienceItem
            key={item.id}
            item={item}
        />
    )

    return (
        <section className="bg-color-dark font-white" id="experiences">
            <div className="container section-margin">

                <SectionHeader
                    header="Experience"
                    quote='"Men love to wonder, and that is the seed of science." - Ralph Waldo Emerson'
                    classes="quote-dark-bg"
                />

                {/* Work Experience sub-section header row */}
                <div className="row" data-aos="fade-down">
                    {/* Education header column */}
                    <div className="col-sm-12">
                        <h3 className="sub-section-title">Work Experience</h3>
                    </div>
                </div>
                {experienceItemComponents}

                {/* Education sub-section header row */}
                <div className="row" data-aos="fade-down">
                    {/* Education header column */}
                    <div className="col-sm-12">
                        <hr className="subsection-separator-light" />
                        <h3 className="sub-section-title">Education</h3>
                    </div>
                </div>
                <ExperienceItem
                    key={educationData.id}
                    item={educationData}
                />
            </div> {/* Experience container */}
        </section>
    )
}

export default Experience