import React from 'react';
import Navbar from './Navbar.jsx';
import Profile from './Profile.jsx';
import Experience from './Experience/Experience.jsx';
import Projects from './Projects/Projects.jsx';
import KeySkills from './KeySkills/KeySkills.jsx';
import PersonalSkills from './PersonalSkills/PersonalSkills.jsx';

const Main = () => {
    return (
        <main className="container-fluid-no-padding parent parent-1" id="parent-1">
            <Navbar />
            <Profile />
            <Experience />
            <Projects />
            <KeySkills />
            <PersonalSkills />
        </main>
    );
};

export default Main;
