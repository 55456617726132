import React from "react"
import TechnologiesItem from "./TechnologiesItem.jsx"

const ProjectItem = ({ data, projectCount }) => {
    const technologiesComponents = data.technologies.map(technology =>
        <TechnologiesItem
            key={technology.title}
            data={technology}
        />
    );

    const isLast = projectCount === data.id;

    return (
        <div className="row" data-aos="zoom-in-down">
            {/* Left column - project tile */}
            <div className="col-xl-6 d-flex justify-content-center">
                <a href={data.url} target="_blank">
                    <figure>
                        <img className="img-fluid project-tile-img" alt="" src={data.imgUrl} />
                        <img className="img-fluid link-icon" alt="" src="img/icons/external-link.png" />
                        <div className="tile-title">{data.header}</div>
                        <figcaption>
                            <p className="m-0">
                                {data.description}
                            </p>
                        </figcaption>
                    </figure>
                </a>
            </div> {/* Left column - tile */}
            {/* Right column - technology icons */}
            <div className="col-xl-6 align-content-center">
                {technologiesComponents}
            </div> {/* right column - technologies */}
            {!isLast && <hr className="col-sm-11 subsection-separator-dark" />}
        </div>
    )
}

export default ProjectItem