import React from "react"

const ExperienceItem = (props) => {
    const jobDescriptionArray = props.item.jobDescription.map(item =>
        <li key={item.description}>{item.description}</li>
    )

    return (
        <div className="row row-spacing" data-aos="zoom-in-down">
            {/* Left column: place, duration */}
            <div className="col-md-4 text-center text-md-left">
                <h5 className="section-text">
                    <strong>{props.item.workplace}</strong>
                </h5>
                <p>{props.item.period}</p>
            </div> {/* column left */}
            {/* Right column: position, description */}
            <div className="col-md-8">
                <h6 className="section-text underline">
                    <strong>{props.item.jobTitle}</strong>
                </h6>
                <ul>
                    {jobDescriptionArray}
                </ul>
            </div> {/* column right */}
        </div>
    )
}

export default ExperienceItem