import React from "react"

const PhotographyItem = (props) => {
    return (
        <div className="img-box">
            <img src={props.data.imgSource} alt={props.data.caption} />
            <div className="transparent-box">
                <div className="caption">
                    <p>{props.data.caption}</p>
                    <p className="opacity-low">{props.data.subCaption}</p>
                </div>
            </div>
        </div>
    )
}

export default PhotographyItem