const projectsData = [
    {
        id: 1,
        url: "https://github.com/MBezzubik/movie-recommender-system-project",
        imgUrl: "img/projects/recommender-website.png",
        header: "Movie Recommender System",
        description: "Movie recommendation website, featuring collaborative filtering recommender system algorithm (SVG), computing top-n item list, based on the preferences of other users",
        technologies: [
            {
                iconSrc: "img/icons/html.svg",
                title: "HTML5"
            },
            {
                iconSrc: "img/icons/css.svg",
                title: "CSS3"
            },
            {
                iconSrc: "img/icons/javascript.svg",
                title: "JavaScript"
            },
            {
                iconSrc: "img/icons/bootstrap.svg",
                title: "Bootstrap"
            },
            {
                iconSrc: "img/icons/vue.svg",
                title: "Vue"
            },
            {
                iconSrc: "img/icons/python.svg",
                title: "Python"
            },
            {
                iconSrc: "img/icons/ajax.png",
                title: "AJAX"
            },
            {
                iconSrc: "img/icons/api-blue.png",
                title: "RESTful API"
            },
            {
                iconSrc: "img/icons/mysql.svg",
                title: "MySQL"
            },
        ]
    },
    {
        id: 2,
        url: "https://legalisation4you.co.uk",
        imgUrl: "img/projects/legalisation4you.png",
        header: "Legalisation Services Website",
        description: "LAMP stack based website offering legalisation services. It uses relational database and implements Stripe payment processor.",
        technologies: [
            {
                iconSrc: "img/icons/html.svg",
                title: "HTML5"
            },
            {
                iconSrc: "img/icons/css.svg",
                title: "CSS3"
            },
            {
                iconSrc: "img/icons/javascript.svg",
                title: "JavaScript"
            },
            {
                iconSrc: "img/icons/bootstrap.svg",
                title: "Bootstrap"
            },
            {
                iconSrc: "img/icons/vue.svg",
                title: "Vue"
            },
            {
                iconSrc: "img/icons/php.svg",
                title: "PHP"
            },
            {
                iconSrc: "img/icons/stripe.svg",
                title: "Stripe"
            },
            {
                iconSrc: "img/icons/api-blue.png",
                title: "RESTful API"
            },
            {
                iconSrc: "img/icons/mysql.svg",
                title: "MySQL"
            },
        ]
    },
    {
        id: 3,
        url: "https://github.com/MBezzubik/cst3130-cw2-data-visualization-website",
        imgUrl: "img/projects/data-visualization.jpeg",
        header: "Data Visualization Website",
        description: "Website that displays visualization of numerical data and the results of sentiment analysis. Data is obtained from web services. The project uses various Amazon Web Services, such as S3, DynamoDB, API Gateway, Comprehend and Lambda",
        technologies: [
            {
                iconSrc: "img/icons/html.svg",
                title: "HTML5"
            },
            {
                iconSrc: "img/icons/css.svg",
                title: "CSS3"
            },
            {
                iconSrc: "img/icons/javascript.svg",
                title: "JavaScript"
            },
            {
                iconSrc: "img/icons/bootstrap.svg",
                title: "Bootstrap"
            },
            {
                iconSrc: "img/icons/vue.svg",
                title: "Vue"
            },
            {
                iconSrc: "img/icons/nodejs.svg",
                title: "Node.js"
            },
            {
                iconSrc: "img/icons/aws.svg",
                title: "Amazon Web Services"
            },
            {
                iconSrc: "img/icons/aws-lambda.svg",
                title: "AWS Lambda"
            },
            {
                iconSrc: "img/icons/dynamodb.svg",
                title: "AWS DynamoDB"
            },
            {
                iconSrc: "img/icons/typescript.svg",
                title: "TypeScript"
            },
            {
                iconSrc: "img/icons/api-blue.png",
                title: "RESTful API"
            }
        ]
    },
    {
        id: 4,
        url: "https://youtu.be/kBlsMwVpxPU",
        imgUrl: "img/projects/arduino.png",
        header: "Memory Box – Arduino",
        description: "Designed and developed a memory game using various physical components, such as Arduino Uno board, buzzer and keypad",
        technologies: [
            {
                iconSrc: "img/icons/arduino.svg",
                title: "Arduino"
            }
        ]
    }
]

export default projectsData