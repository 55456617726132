import React from "react"
import SectionHeader from "../../shared/SectionHeader.jsx"
import KeySkillItem from "./KeySkillItem.jsx"
import keySkillsData from "./keySkillsData"

const KeySkills = () => {
    const categories = ['frontend', 'devops', 'backend', 'databases']
    const components = []

    for( const category of categories) {
        components.push(
            keySkillsData.filter( skill => skill.type.includes(category)).map( filteredSkill =>
                <KeySkillItem
                    key={filteredSkill.name}
                    data={filteredSkill}
                />
            )
        )
    }

    return (
        <section className="bg-color-dark font-white" id="key-skills">
            <div className="container section-margin">
                {/* Experience header row */}
                <SectionHeader
                    header="Key Skills"
                    quote='"Everything is theoretically impossible, until it is
                    done." - Robert A. Heinlein'
                    classes="quote-dark-bg"
                />
                
                {/* Frontend sub-section header row */}
                <div className="row" data-aos="fade-down">
                    {/* Frontend header column */}
                    <div className="col-sm-12">
                        <h3 className="sub-section-title text-center-media-query mb-0">Frontend</h3>
                    </div>
                </div>

                {/* Frontend content row */}
                <div className="row justify-content-center" data-aos="zoom-in-down">
                    {components[0]}
                    <div className="col-sm-12">
                        <hr className="subsection-separator-light" />
                    </div>
                </div>

                {/* DevOps sub-section header row */}
                <div className="row" data-aos="fade-down">
                    {/* DevOps header column */}
                    <div className="col-sm-12">
                        <h3 className="sub-section-title text-center-media-query mb-0">DevOps</h3>
                    </div>
                </div>

                <div className="row justify-content-center" data-aos="zoom-in-down">
                    {components[1]}
                    <div className="col-sm-12">
                        <hr className="subsection-separator-light" />
                    </div>
                </div>

                {/* Backend sub-section header row */}
                <div className="row" data-aos="fade-down">
                    {/* Backend header column */}
                    <div className="col-sm-12">
                        <h3 className="sub-section-title text-center-media-query mb-0">Backend and Other</h3>
                    </div>
                </div>

                <div className="row justify-content-center" data-aos="zoom-in-down">
                    {components[2]}
                    <div className="col-sm-12">
                        <hr className="subsection-separator-light" />
                    </div>
                </div>

                {/* Databases sub-section header row */}
                <div className="row" data-aos="fade-down">
                    {/* Databases header column */}
                    <div className="col-sm-12">
                        <h3 className="sub-section-title text-center-media-query mb-0">Databases</h3>
                    </div>

                </div> {/* Databases header */}
                <div className="row justify-content-center" data-aos="zoom-in-down">
                    {components[3]}
                </div>
            </div> {/* container */}
        </section>
    )
}

export default KeySkills