import React from "react"
import { Navbar, Nav } from "react-bootstrap"


class MyNavbar extends React.Component {

    render() {
        return (
            <Navbar sticky="top" collapseOnSelect className="header-nav" expand="lg">
                    <Navbar.Toggle className="toggle-button" variant="primary"  aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="m-auto">
                            <Nav.Link href="#profile">Profile</Nav.Link>
                            <Nav.Link href="#experiences">Experience</Nav.Link>
                            <Nav.Link href="#projects">Projects</Nav.Link>
                            <Nav.Link href="#key-skills">Key Skills</Nav.Link>
                            <Nav.Link href="#personal-skills">Personal</Nav.Link>
                            <Nav.Link href="#contact">Contact</Nav.Link>
                            <Nav.Link style={{ color: '#2fe25c' }} href="./downloadable-content/maciej-bezzubik-cv.pdf" target="_blank" rel="noopener noreferrer">Get CV</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default MyNavbar