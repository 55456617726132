const keySkillsData = [
    {
        iconSrc: "img/icons/typescript.svg",
        name: "TypeScript",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/javascript.svg",
        name: "JavaScript",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/react.svg",
        name: "React",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/nextjs.svg",
        name: "Next.js",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/vue.svg",
        name: "Vue",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/nuxt.svg",
        name: "Nuxt",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/html.svg",
        name: "HTML",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/css.svg",
        name: "CSS",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/sass.svg",
        name: "Sass",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/styled-components.png",
        name: "Styled Compts",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/tailwindcss.svg",
        name: "TailwindCSS",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/bootstrap.svg",
        name: "Bootstrap",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/storybook.svg",
        name: "Storybook",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/jest.svg",
        name: "Jest",
        type: "frontend"
    },
    {
        iconSrc: "img/icons/cypress.svg",
        name: "Cypress",
        type: "frontend"
    },

    {
        iconSrc: "img/icons/aws-sqare.png",
        name: "AWS",
        type: "devops"
    },
    {
        iconSrc: "img/icons/azure-devops.svg",
        name: "Azure DevOps",
        type: "devops"
    },
    {
        iconSrc: "img/icons/azure-pipelines.svg",
        name: "Azure Pipelines",
        type: "devops"
    },
    {
        iconSrc: "img/icons/docker.svg",
        name: "Docker",
        type: "devops"
    },
    {
        iconSrc: "img/icons/git.svg",
        name: "Git",
        type: "devops"
    },

    {
        iconSrc: "img/icons/.",
        name: "",
        type: "AWS Lambda"
    },
    {
        iconSrc: "img/icons/nodejs.svg",
        name: "Node.js",
        type: "backend"
    },
    {
        iconSrc: "img/icons/express.svg",
        name: "Express",
        type: "backend"
    },
    {
        iconSrc: "img/icons/python.svg",
        name: "Python",
        type: "backend"
    },
    {
        iconSrc: "img/icons/flask.svg",
        name: "Flask",
        type: "backend"
    },
    {
        iconSrc: "img/icons/api-blue.png",
        name: "REST API",
        type: "backend"
    },
    {
        iconSrc: "img/icons/arduino.svg",
        name: "Arduino",
        type: "backend"
    },

    {
        iconSrc: "img/icons/mysql.svg",
        name: "MySQL",
        type: "databases"
    },
    {
        iconSrc: "img/icons/mongodb.png",
        name: "MongoDB",
        type: "databases"
    },
    {
        iconSrc: "img/icons/dynamodb.svg",
        name: "DynamoDB",
        type: "databases"
    },
    {
        iconSrc: "img/icons/sqlite.png",
        name: "SQLite",
        type: "databases"
    }
]

export default keySkillsData