const photographyData = [
    {
        id: 1,
        imgSource: "img/gallery/image-01-big-ben.JPG",
        caption: "Big Ben",
        subCaption: "Architecture"
    },
    {
        id: 2,
        imgSource: "img/gallery/image-02-putney-bridge.JPG",
        caption: "Putney Bridge",
        subCaption: "Landscape"
    },
    {
        id: 3,
        imgSource: "img/gallery/image-04-cat.jpg",
        caption: "Felis Catus",
        subCaption: "Nature"
    },
    {
        id: 4,
        imgSource: "img/gallery/image-07-ribbon.jpg",
        caption: "Ribbon",
        subCaption: "Cinematic"
    },
    {
        id: 5,
        imgSource: "img/gallery/image-06-flower.jpg",
        caption: "Lilium",
        subCaption: "Nature"
    },
    {
        id: 6,
        imgSource: "img/gallery/image-05-longhorn-beetle.jpg",
        caption: "Longhorn Beetle",
        subCaption: "Macro"
    }
]

export default photographyData