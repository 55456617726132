import React, {useState, useEffect} from "react"

const Footer = () => {
    const [year, setYear] = useState(null)

    useEffect(() => {
        setYear(
            new Date().getFullYear()
        )
    })

    return (
        <footer id="contact">
            <div className="container">
                <div className="row">
                    <h2 className="col-sm-12 text-center section-title" style={{ color: 'white' }}>Contact</h2>
                    <p className="col-sm-12 text-center quote-dark-bg">"The brain is like a muscle. When it is in use
                        we feel very good. Understanding is joyous." - Carl Sagan</p>
                    <div className="col-sm-12">
                        <hr />
                    </div>
                    {/* Contact icons */}
                    <div className="col-sm-12 contact-icons">
                        <ul>
                            <li className="linkedin"><a href="https://www.linkedin.com/in/maciej-bezzubik/" target="_blank"  rel="noreferrer"><i className="fab fa-linkedin-in fa-2x" aria-hidden="true" /></a></li>
                            <li className="email"><a href="mailto:m.bezzubik@gmail.com" target="_blank" rel="noreferrer"><i className="far fa-envelope fa-2x" aria-hidden="true" /></a></li>
                            <li className="github"><a href="https://github.com/MBezzubik" target="_blank"  rel="noreferrer"><i className="fab fa-github fa-2x" aria-hidden="true" /></a></li>
                        </ul>
                    </div> {/* Contact icons */}
                    <div className="col-sm-12 text-center footer-text" style={{ border: 0 }}>
                        Interested in hiring me? Download 
                        <a href="./downloadable-content/maciej-bezzubik-cv.pdf" target="_blank" rel="noopener noreferrer" className="text-highlight"> my resume!</a>
                    </div>
                </div> {/* row */}
            </div> {/* container */}
            <div className="container-fluid text-center footer-text">
                <small>{`Maciej Bezzubik ©${year}`}</small>
            </div>
        </footer>
    )
}

export default Footer