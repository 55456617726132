import React from "react"

const SectionTitle = (props) => {
    let className = "text-center " + props.classes

    return (
        <div className="row" data-aos="fade-down">
            <div className="col-sm-12 text-center">
                <h2 className="section-title">{props.header}</h2>
            </div>
            <div className="col-sm-12">
                <p className={className}>
                    {props.quote}
                </p>
                <hr />
            </div>
        </div>
    )
}

export default SectionTitle
